import React from 'react';
import {Link, isExperienceEditorActive} from '@sitecore-jss/sitecore-jss-react';
import {DefaultProps, PropTypes} from '../../../core/Utils/SitecoreFields';
import ImageContentLink from '../../../core/Components/ImageContentLink';

const ContentCard = ({fields}) => {
	return isExperienceEditorActive() ? (
		<div className="content-card clearfix">
			<ImageContentLink fields={fields} />
		</div>
	) : (
		<Link field={fields.link} title={fields.link.value.title || fields.title.value} className="content-card clearfix">
			<ImageContentLink fields={fields} />
		</Link>
	);
};

ContentCard.propTypes = PropTypes.SitecoreItem.inject(PropTypes.ImageContentLink);
ContentCard.defaultProps = DefaultProps.SitecoreItem.inject(DefaultProps.ImageContentLink);

export default ContentCard;
